import {useConfigProvider} from "../../../config"
import {getBrowserName} from "../../../helpers/getBrowserName"
import {getOS} from "../../../helpers/getOS"
import {FabricEvent} from "../../../types"

export const fabricEndpoints = () => {
  const {token, userProfileServerUrl, userProfileApimKey, userProfileFaToken} =
    useConfigProvider()

  const headers = {
    "Content-Type": "application/json",
    "X-Functions-Key": userProfileFaToken || "",
    "Ocp-Apim-Subscription-Key": userProfileApimKey || "",
    Authorization: `Bearer ${token}`,
  }

  const sendFabricEvents = async (events: FabricEvent[]) => {
    const payload = events.map((event) => ({
      ...event,
      browser: getBrowserName(navigator.userAgent),
      operatingSystem: getOS(navigator.userAgent),
    }))
    const request = new Request(`${userProfileServerUrl}/utility/fabric`, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    })

    const response = await fetch(request)

    if (!response.ok) {
      throw new Error("Failed to send events")
    }
  }

  return {
    sendFabricEvents,
  }
}
