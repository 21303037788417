import {type FC, Fragment, useEffect, useState, MouseEvent} from "react"
import * as yup from "yup"
import DOMPurify from "dompurify"
import {toast} from "react-toastify"
import {Controller, useForm, UseFormTrigger} from "react-hook-form"
import {useMutation, useQuery} from "@tanstack/react-query"
import {yupResolver} from "@hookform/resolvers/yup"
import {
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Popover,
  Slider,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material"
import {ErrorMessage} from "./ErrorMessage"
import {RichTextField, SearchBar, TextWithTooltip} from "../../molecules"
import {useConfigProvider} from "../../../../config"
import {appInsights} from "../../../../config/appInsights"
import {notificationEndpoints} from "../../../../services/api/notification"
import {DateInput, Input, NewButton, Select, Tooltip} from "../../atoms"
import {
  ChevronDownIcon,
  CloseIcon,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
} from "../../../../assets/icons"
import type {AskAnExpertNotification} from "../../../../services/types/notification"
import {
  filterEmojis,
  stringIsLink,
  stringIsNotSQL,
  stringIsScript,
} from "../../../../helpers"
import {
  deliveryType,
  formatCurrency,
  generateSupportedTimeZones,
  getBrowserTimeZoneDescription,
} from "./utils"
import PhoneInput from "react-phone-number-input/react-hook-form"
import {
  getCountries,
  getCountryCallingCode,
  isSupportedCountry,
} from "react-phone-number-input/input"
import countryLabels from "react-phone-number-input/locale/en.json"
import {useTranslation} from "react-i18next"
import useStorage, {StorageData} from "../../../../hooks/useStorage"
import {AccountTeam} from "../../../../types/Account"
import {accountEndpoints} from "../../../../services"

const countryCodes = getCountries()

const countries = countryCodes
  .map((countryCode) => ({
    label: countryLabels[countryCode],
    value: countryCode,
    callingCode: getCountryCallingCode(countryCode),
  }))
  .sort((a, b) => a.label.localeCompare(b.label))

type Props = {}

const deliveryTypesWithRequiredDate = ["presentation", "phone call"]

const createSchema = (countryCodeNumber: string) => {
  const phoneMinLength = countryCodeNumber.length + 7 //7 because of the + sign

  return yup
    .object({
      DeliveryType: yup.string().required("Delivery type is required"),
      RelatesTo: yup.string().required(),
      RequestBudget: yup.string(),
      Timezone: yup.string().required(),
      Description: yup
        .string()
        .required("Description is required")
        .min(3, "Minimum 3 characters")
        .max(2000, "Maximum 2000 characters")
        .matches(stringIsLink, "Links are not allowed")
        .matches(stringIsScript, "Invalid text format")
        .matches(stringIsNotSQL, "Invalid text format"),
      BusinessPhone: yup
        .string()
        .required("Business phone is required")
        .max(50, "Maximum 50 characters")
        .min(phoneMinLength, `Minimum 6 characters`)
        .matches(/^[0-9\s()\-+]*$/, "Invalid text format"),
      PreferredCallDatesStart: yup.string().when("DeliveryType", {
        is: (value: string) =>
          deliveryTypesWithRequiredDate.includes(value?.toLowerCase()),
        then: (schema) =>
          schema.required("Preferred call or presentation dates are required"),
        otherwise: (schema) => schema,
      }),
      PreferredCallDatesEnd: yup.string().when("DeliveryType", {
        is: (value: string) =>
          deliveryTypesWithRequiredDate.includes(value?.toLowerCase()),
        then: (schema) =>
          schema.required("Preferred call or presentation dates are required"),
        otherwise: (schema) => schema,
      }),
    })
    .required()
}
type FormData = yup.InferType<ReturnType<typeof createSchema>>
/*
let schema = yup
  .object({
    DeliveryType: yup.string().required("Delivery type is required"),
    RelatesTo: yup.string().required(),
    RequestBudget: yup.string(),
    Timezone: yup.string().required(),
    Description: yup
      .string()
      .required("Description is required")
      .min(3, "Minimum 3 characters")
      .max(2000, "Maximum 2000 characters")
      .matches(stringIsLink, "Links are not allowed")
      .matches(stringIsScript, "Invalid text format")
      .matches(stringIsNotSQL, "Invalid text format"),
    BusinessPhone: yup
      .string()
      .required("Business phone is required")
      .max(50, "Maximum 50 characters")
      .matches(/^[0-9\s()+-]*$/, "Invalid text format"),
    PreferredCallDatesStart: yup.string().when("DeliveryType", {
      is: (value: string) =>
        deliveryTypesWithRequiredDate.includes(value?.toLowerCase()),
      then: (schema) =>
        schema.required("Preferred call or presentation dates are required"),
      otherwise: (schema) => schema,
    }),
    PreferredCallDatesEnd: yup.string().when("DeliveryType", {
      is: (value: string) =>
        deliveryTypesWithRequiredDate.includes(value?.toLowerCase()),
      then: (schema) =>
        schema.required("Preferred call or presentation dates are required"),
      otherwise: (schema) => schema,
    }),
  })
  .required()
type FormData = yup.InferType<typeof schema>*/

export const AskAnExpertModal: FC<Props> = (props) => {
  const theme = useTheme()
  const {user} = useConfigProvider()
  const [descriptionHtmlContent, setDescriptionHtmlContent] =
    useState<string>("")
  const [showModal, setShowModal] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<string>("")
  const [countryCodeNumber, setCountryCodeNumber] = useState<string>("44")
  const {t} = useTranslation()
  const {getAccountTeamByAccountNumber} = accountEndpoints()
  const crmAccount = useStorage("session", StorageData.crmAccount)

  const getAccountTeamQuery = useQuery<AccountTeam[]>({
    queryKey: ["accountTeam", crmAccount.get()],
    queryFn: () => getAccountTeamByAccountNumber(crmAccount.get() || ""),
    enabled: !!crmAccount.get() && crmAccount.get() !== "001",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
    watch,
    setError,
    clearErrors,
    trigger,
    setValue,
  } = useForm({
    resolver: yupResolver(createSchema(countryCodeNumber)),
    mode: "onBlur",
    defaultValues: {
      DeliveryType: "",
      RequestBudget: "1500",
    },
  })

  const {sendAskAnExpertNotificationEmail} = notificationEndpoints()

  const sendNotificationMutation = useMutation<
    any,
    Error,
    AskAnExpertNotification
  >({
    mutationFn: sendAskAnExpertNotificationEmail,
    mutationKey: ["sendNotification"],
  })

  const handleClose = () => {
    setCountryCodeNumber("44")
    setShowModal(false)
    setCurrentPage("")
    reset()
  }
  const onSubmit = async (data: FormData) => {
    const deliveryType = DOMPurify.sanitize(data.DeliveryType)
    const description = filterEmojis(descriptionHtmlContent)

    const mappedCCEmails = getAccountTeamQuery?.data?.map(
      (t) => t?.accountTeamEmailAddress,
    )

    const notificationPayload = {
      notificationType: "AskAnExpert-ToClientAndInternal",
      relatesTo: currentPage,
      deliveryType: deliveryType,
      description: DOMPurify.sanitize(description || ""),
      user: {
        userFirstname: user.firstname,
        userEmail: user.email,
        userOwnername: user.firstname,
      },
      deliveryTimezone: data.Timezone,
      requestBudget: formatCurrency(data.RequestBudget || ""),
      preferredCallOrPresentationDates:
        data.PreferredCallDatesStart && data.PreferredCallDatesEnd
          ? `${new Date(data.PreferredCallDatesStart).toLocaleDateString(
              "en-GB",
            )} or ${new Date(data.PreferredCallDatesEnd).toLocaleDateString(
              "en-GB",
            )}`
          : "N/A",
      businessPhone: data.BusinessPhone,
      ccAddresses: mappedCCEmails!!,
    } satisfies AskAnExpertNotification

    try {
      const notificationResponse = await sendNotificationMutation.mutateAsync(
        notificationPayload,
      )

      if (!!notificationResponse.status || !!notificationResponse.statusCode) {
        var completedTour = localStorage.getItem("completedTour")
        if (!!completedTour) {
          toast.error(
            "An unexpected error occurred. Please try refresh your page.",
            {
              autoClose: 3000,
              icon: ErrorIcon,
              toastId: "api-error-toast",
            },
          )
        } else {
          var completed = localStorage.getItem("completedTour")
          var interval = setInterval(() => {}, 3000)
          if (!!completed) clearInterval(interval)
        }
        return
      }
      appInsights.trackEvent({
        name: "ASK_AN_EXPERT",
        properties: {
          userId: user.userId,
          userName: `${user.firstname} ${user.lastname}`,
          email: user.email,
          relatesTo: currentPage,
        },
      })
      toast.success("Successful! Your request has been submitted", {
        autoClose: 3000,
        style: {
          borderLeftColor: "#58A733",
        },
        icon: SuccessIcon,
      })
      handleClose()
    } catch (error) {
      console.log(error)
    }
    reset()
  }

  const PreferredCallDatesStart = watch("PreferredCallDatesStart")
  const PreferredCallDatesEnd = watch("PreferredCallDatesEnd")
  useEffect(() => {
    if (PreferredCallDatesStart && PreferredCallDatesEnd) {
      if (
        new Date(PreferredCallDatesStart) >= new Date(PreferredCallDatesEnd)
      ) {
        setError("PreferredCallDatesStart", {
          type: "manual",
          message: "Invalid date",
        })
      } else {
        clearErrors("PreferredCallDatesStart")
      }
    }
  }, [PreferredCallDatesStart, PreferredCallDatesEnd, setError, clearErrors])

  const handleDialogEvent = (
    event: CustomEvent<{relatesTo: string; ccAddresses: string[]}>,
  ) => {
    setCurrentPage(event.detail.relatesTo)
    setShowModal(true)
  }

  useEffect(() => {
    window.addEventListener("ask-an-expert", handleDialogEvent as EventListener)

    return () => {
      window.removeEventListener(
        "ask-an-expert",
        handleDialogEvent as EventListener,
      )
    }
  }, [])

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      sx={{
        "&.MuiModal-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
        }}
      >
        <Box
          borderRadius="0.5rem 0.5rem 0 0"
          bgcolor="#053747"
          height="10px"
        ></Box>
        <Box
          bgcolor="white"
          px="24px"
          pt="20px"
          pb="1rem"
          borderRadius="0 0 0.5rem 0.5rem"
          width="712px"
          fontFamily="14px"
        >
          <Stack direction="row" justifyContent={"space-between"} pb={"10px"}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              fontSize="20px"
              fontWeight={600}
            >
              {t("New expert request")}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />

          {/* RelatesTo & DeliveryType */}
          <Stack flexDirection="row" gap="16px" pt={"1rem"}>
            <Stack flexGrow={1} maxWidth={"50%"}>
              <Controller
                control={control}
                name="RelatesTo"
                defaultValue={t(currentPage)}
                render={({field}) => (
                  <Input
                    label="Relates to"
                    required
                    error={errors.RelatesTo?.message}
                    textFieldProps={{
                      disabled: true,
                      id: "subject",
                      placeholder: "Relates to",
                      sx: {
                        ".MuiOutlinedInput-input": {
                          backgroundColor: "#BDBDBC",
                        },
                      },
                      InputProps: {
                        sx: {
                          fontSize: "14px",
                          ".MuiOutlinedInput-input.Mui-disabled": {
                            backgroundColor: "#BDBDBC",
                            color: "#242D35",
                            opacity: "unset",
                            "-webkit-text-fill-color": "#242D35",
                          },
                        },
                      },
                      ...field,
                    }}
                  />
                )}
              />
            </Stack>
            <Stack flexGrow={1}>
              <Controller
                control={control}
                name="DeliveryType"
                render={({field}) => (
                  <Select
                    selectFieldProps={{
                      required: true,
                      fullWidth: true,
                      MenuProps: {
                        sx: {
                          ".MuiPaper-root": {
                            backgroundColor: "white",
                          },
                        },
                      },
                      sx: {
                        fontSize: "14px",
                      },
                      ...field,
                    }}
                    error={errors.DeliveryType?.message}
                    formGroupProps={{}}
                    label="Delivery type"
                    required
                    placeholder="Select delivery type"
                  >
                    {deliveryType.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.type}
                        sx={{fontSize: "14px"}}
                      >
                        <Stack
                          gap={"8px"}
                          direction={"row"}
                          alignItems={"center"}
                        >
                          {<option.icon />}
                          {t(option.type)}
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Stack>
          </Stack>
          {/* Description */}
          <Stack>
            <Controller
              control={control}
              name="Description"
              render={({field}) => (
                <RichTextField
                  formError={errors.Description?.message}
                  {...field}
                  onChange={(value) => {
                    setDescriptionHtmlContent(value.html)
                    field.onChange(value.text)
                  }}
                  placeholder="Enter description"
                  label="Description"
                />
              )}
            />
          </Stack>
          {/* Timezone & Request budget */}
          <Stack flexDirection="row" gap="26px" alignItems={"start"}>
            <Stack flexGrow={1} maxWidth={"50%"}>
              <Controller
                control={control}
                name="Timezone"
                defaultValue={getBrowserTimeZoneDescription()}
                render={({field}) => (
                  <Select
                    selectFieldProps={{
                      fullWidth: true,
                      MenuProps: {
                        sx: {".MuiPaper-root": {backgroundColor: "white"}},
                      },
                      sx: {fontSize: "14px"},
                      ...field,
                    }}
                    formGroupProps={{}}
                    label="Delivery time zone"
                    required
                  >
                    {generateSupportedTimeZones().map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option}
                        sx={{fontSize: "14px"}}
                      >
                        <Stack
                          gap={"8px"}
                          direction={"row"}
                          alignItems={"center"}
                        >
                          {option}
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Stack>
            <Stack flexGrow={1}>
              <Controller
                control={control}
                name="RequestBudget"
                render={({field}) => (
                  <Stack>
                    <Stack direction="row" gap={"8px"}>
                      <InputLabel
                        sx={{
                          color: errors.RequestBudget?.message
                            ? theme.palette.error.main
                            : theme.palette.primary.main,
                          fontWeight: 400,
                          fontSize: "0.875rem",
                        }}
                      >
                        {t("Request budget")}
                      </InputLabel>
                      <Tooltip
                        title={
                          <Stack alignItems={"start"} pr={5}>
                            <Typography whiteSpace="pre-line">
                              {t("Indicative only - subject to scoping", {
                                defaultValue:
                                  "Indicative only - subject to\nscoping",
                              })}
                            </Typography>
                          </Stack>
                        }
                        arrow
                        placement="right"
                      >
                        <Stack>
                          <InfoIcon />
                        </Stack>
                      </Tooltip>
                    </Stack>
                    <Slider
                      {...field}
                      value={Number(field.value) || 1500}
                      onChange={(_, value) => field.onChange(value)}
                      defaultValue={1500}
                      min={1500}
                      max={55000}
                      step={Number(field.value) <= 10000 ? 500 : 1000}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) =>
                        value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      slotProps={{
                        thumb: {
                          style: {
                            backgroundColor: "#FFF",
                            border: `2px solid ${theme.palette.primary.main}`,
                          },
                        },
                      }}
                      sx={{
                        ".MuiSlider-valueLabel": {
                          bgcolor: theme.palette.primary.main,
                          borderRadius: "5px",
                          py: "6px",
                        },
                      }}
                    />
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      sx={{
                        color: theme.palette.info.contrastText,
                      }}
                    >
                      <Typography fontSize={"14px"}>USD 1,500</Typography>
                      <Typography fontSize={"14px"}>USD 55,000</Typography>
                    </Stack>
                  </Stack>
                )}
              />
            </Stack>
          </Stack>
          {/* PreferredCallOrPresentation & BusinessPhone */}
          <Stack flexDirection="row" gap="16px" alignItems={"start"} pt="16px">
            <Stack flexGrow={1} maxWidth={"50%"} gap={"0.25rem"}>
              <Stack direction={"row"} alignItems={"center"}>
                <Stack direction={"row"} pr={"5px"}>
                  <Typography
                    color={
                      errors.PreferredCallDatesStart?.message ||
                      errors.PreferredCallDatesEnd?.message
                        ? "#DB0D00"
                        : "#053747"
                    }
                    fontSize={"0.875rem"}
                  >
                    {t("Preferred call or presentation dates")}
                  </Typography>
                  {deliveryTypesWithRequiredDate.includes(
                    watch("DeliveryType")?.toLocaleLowerCase(),
                  ) && (
                    <span
                      style={{
                        color: "#DB0D00",
                        fontSize: "0.875rem",
                      }}
                    >
                      &nbsp; *
                    </span>
                  )}
                </Stack>
                <Tooltip
                  title={
                    <Stack alignItems={"start"} pr={5}>
                      {/* <Typography>We will do our best to</Typography>
                      <Typography>accomodate your request</Typography> */}
                      <Typography whiteSpace="pre-line">
                        {t("We will do our best to accomodate your request", {
                          defaultValue:
                            "We will do our best to\n accomodate your request",
                        })}
                      </Typography>
                    </Stack>
                  }
                  arrow
                  placement="right"
                  sx={{".MuiTooltip-tooltip": {bgcolor: "#053747"}}}
                >
                  <Stack>
                    <InfoIcon />
                  </Stack>
                </Tooltip>
              </Stack>
              <Stack direction={"row"} gap={"4px"}>
                <Stack flexGrow={1} maxWidth={"50%"}>
                  <Controller
                    control={control}
                    name="PreferredCallDatesStart"
                    render={({field, fieldState}) => (
                      <Stack>
                        <DateInput
                          popperClassName="custom-datepicker"
                          wrapperClassName="w-full"
                          className={`form-date-input fill ${
                            fieldState.error?.message?.toString() ? "error" : ""
                          }`}
                          showPopperArrow={false}
                          id="PreferredCallDatesStart"
                          dateFormat="dd/MM/yyyy"
                          disabled={false}
                          selected={
                            field.value ? new Date(field.value) : undefined
                          }
                          placeholderText="dd/mm/yyyy"
                          minDate={new Date()}
                          maxDate={
                            PreferredCallDatesEnd
                              ? new Date(PreferredCallDatesEnd)
                              : undefined
                          }
                          autoComplete="off"
                          onChange={(date) => {
                            if (date) {
                              setValue(
                                "PreferredCallDatesStart",
                                date.toISOString(),
                              )
                            }
                          }}
                          onBlur={field.onBlur}
                          containerProps={{
                            sx: {
                              input: {
                                fontSize: "14px",
                                lineHeight: "1.43",
                                color: "#212121",
                                "::placeholder": {
                                  color: "#7a7a79",
                                  fontWeight: "400",
                                },
                              },
                            },
                          }}
                        />
                      </Stack>
                    )}
                  />
                </Stack>
                <Stack flexGrow={1} maxWidth={"50%"}>
                  <Controller
                    control={control}
                    name="PreferredCallDatesEnd"
                    render={({field, fieldState}) => (
                      <Stack>
                        <DateInput
                          popperClassName="custom-datepicker"
                          wrapperClassName="w-full"
                          className={`form-date-input fill ${
                            fieldState.error?.message?.toString() ? "error" : ""
                          }`}
                          showPopperArrow={false}
                          id="PreferredCallDatesEnd"
                          dateFormat="dd/MM/yyyy"
                          disabled={false}
                          selected={
                            field.value ? new Date(field.value) : undefined
                          }
                          placeholderText="dd/mm/yyyy"
                          minDate={
                            PreferredCallDatesStart
                              ? new Date(PreferredCallDatesStart)
                              : new Date()
                          }
                          autoComplete="off"
                          onChange={(date) => {
                            if (date) {
                              setValue(
                                "PreferredCallDatesEnd",
                                date.toISOString(),
                              )
                            }
                          }}
                          onBlur={field.onBlur}
                          containerProps={{
                            sx: {
                              input: {
                                fontSize: "14px",
                                lineHeight: "1.43",
                                color: "#212121",
                                "::placeholder": {
                                  color: "#7a7a79",
                                  fontWeight: "400",
                                },
                              },
                            },
                          }}
                        />
                      </Stack>
                    )}
                  />
                </Stack>
              </Stack>
              {!!errors.PreferredCallDatesEnd?.message &&
                !errors.PreferredCallDatesStart?.message && (
                  <ErrorMessage
                    message={errors.PreferredCallDatesEnd.message}
                  />
                )}
              {!!errors.PreferredCallDatesStart?.message && (
                <ErrorMessage
                  message={errors.PreferredCallDatesStart.message}
                />
              )}
            </Stack>
            <Stack flexGrow={1} pt={"1px"}>
              {/*<Controller
                control={control}
                name="BusinessPhone"
                render={({field}) => (
                  <Input
                    label="Business phone"
                    required
                    error={errors.BusinessPhone?.message}
                    textFieldProps={{
                      id: "subject",
                      placeholder: "+0 000 0000 0000",
                      sx: {
                        ".MuiInputBase-input": {
                          p: "8.5px 14px",
                          borderRadius: "4px",
                        },
                      },
                      InputProps: {
                        sx: {
                          fontSize: "14px",
                        },
                      },
                      ...field,
                    }}
                  />
                )}
              />*/}
              {
                <Controller
                  control={control}
                  name="BusinessPhone"
                  render={({field, fieldState}) => (
                    <FormGroup
                      sx={{
                        ".PhoneInput": {
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "8px",
                        },
                        input: {
                          padding: "8px 16px",
                          fontSize: "14px",
                          lineHeight: "20px",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderColor: fieldState.error ? "#DB0D00" : "#BDBDBC",
                          borderRadius: "4px",
                          color: "#242d35",
                          height: "32px",
                          boxSizing: "border-box",
                          transition: "border-color 0.2s ease-in-out",
                          outline: "none",
                          fontFamily:
                            "'Open Sans', Arial, Helvetica, sans-serif !important",

                          "::placeholder": {
                            color: "#7A7A79 !important",
                          },

                          "&:disabled": {
                            backgroundColor: "#BDBDBC",
                            borderColor: "#BDBDBC",
                            color: "#242d35",
                          },
                        },
                      }}
                    >
                      <InputLabel
                        htmlFor="BusinessPhone"
                        error={!!errors.BusinessPhone?.message}
                        sx={{
                          color: "#053747",
                          fontSize: "14px",
                          lineHeight: "1.5",
                        }}
                      >
                        Business phone
                      </InputLabel>
                      <PhoneInput
                        control={control}
                        name="BusinessPhone"
                        placeholder="Work phone number"
                        defaultCountry="GB"
                        countrySelectComponent={CountrySelect}
                        countrySelectProps={{
                          error: !!errors.BusinessPhone?.message,
                          trigger: trigger,
                          setCountryCodeNumber: setCountryCodeNumber,
                        }}
                        flagUrl="/flags/{XX}.svg"
                        focusInputOnCountrySelection={true}
                      />
                      {!!errors.BusinessPhone?.message && (
                        <ErrorMessage message={errors.BusinessPhone.message} />
                      )}
                    </FormGroup>
                  )}
                />
              }
            </Stack>
          </Stack>

          <Stack
            flexDirection="row"
            gap={"5px"}
            justifyContent="end"
            pt="50px"
            px="8px"
          >
            <NewButton
              variant="outline"
              color="primary"
              size="medium"
              text={t("Cancel")}
              onClick={handleClose}
              sx={{
                width: "88px",
                backgroundColor: "white",
                color: "#053747",
                textDecoration: "underline",
                textUnderlineOffset: "5px",
                whiteSpace: "nowrap",
                border: "unset",
                "&:hover": {
                  borderBottomWidth: "2px",
                  bgcolor: "transparent",
                  paddingTop: "2px",
                  textDecoration: "underline",
                },
                "&:active": {
                  backgroundColor: "transparent",
                },
              }}
            />
            <NewButton
              variant="filled"
              color="primary"
              size="medium"
              text={t("Submit")}
              type="submit"
              loading={!!sendNotificationMutation.isPending}
              sx={{
                width: "136px",
              }}
            />
          </Stack>
        </Box>
      </Stack>
    </Modal>
  )
}

AskAnExpertModal.displayName = "AskAnExpertModal"
export default AskAnExpertModal

const FormGroup = styled("article")(({theme}) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  flexWrap: "nowrap",
  flex: 1,
}))

type CountrySelectProps = {
  value: string
  iconComponent: any
  onChange: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
  error?: boolean
  disabled?: boolean
  trigger: UseFormTrigger<FormData>
  setCountryCodeNumber: (value: string) => void
}
const CountrySelect: FC<CountrySelectProps> = (props) => {
  const {
    value,
    iconComponent: Icon,
    onChange,
    onFocus,
    error,
    disabled,
    trigger,
    setCountryCodeNumber,
  } = props
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [filteredCountries, setFilteredCountries] = useState(countries)

  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
    onFocus?.()
  }

  const handleClose = () => {
    setAnchorEl(null)
    setFilteredCountries(countries)
    trigger("BusinessPhone", {shouldFocus: true})
  }

  const handleSearchChange = (value: string) => {
    if (disabled) return
    if (value) {
      setFilteredCountries(
        countries.filter(
          (option) =>
            option.label.toLowerCase().startsWith(value.toLowerCase()) ||
            option.callingCode.includes(value),
        ),
      )
    } else {
      setFilteredCountries(countries)
    }
  }

  const handleCountrySelect = (country: string, callingCode: string) => {
    if (disabled) return
    onChange(country)
    setCountryCodeNumber(callingCode || "")
    handleClose()
  }

  return (
    <Fragment>
      <Box width="auto" position="relative">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          paddingY="7px"
          paddingLeft="16px"
          paddingRight="8px"
          borderRadius="4px"
          gap="24px"
          height="32px"
          onClick={handleOpen}
          sx={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: error && !disabled ? "#DB0D00" : "#BDBDBC",
            backgroundColor: disabled ? "#BDBDBC" : "#FFFFFF",
            color: "#242d35",
            boxSizing: "border-box",
            userSelect: "none",
            cursor: disabled ? "default" : "pointer",
            transition: "border-color 0.2s ease-in-out",

            svg: {
              transform: anchorEl ? "rotate(180deg)" : "rotate(0deg)",
            },
          }}
        >
          <Stack direction="row" gap="8px" alignItems="center" fontSize="14px">
            {value ? <Icon label={value} aria-hidden country={value} /> : null}
            <Typography
              aria-label="country-code"
              component="span"
              fontSize="14px"
              fontWeight={400}
              lineHeight="1.43"
              color="#242d35"
            >
              {isSupportedCountry(value)
                ? `+${getCountryCallingCode(value)}`
                : null}
            </Typography>
          </Stack>
          <ChevronDownIcon />
        </Stack>
        <Box
          className="focus-indicator"
          sx={{
            position: "absolute",
            bottom: "0",
            left: "0",
            right: "0",
            height: "1.6px",
            width: "100%",
            opacity: Boolean(anchorEl) && !error ? 1 : 0,
            backgroundColor: "#7A7A79",
            borderBottomLeftRadius: "2px",
            borderBottomRightRadius: "2px",
            transition: "opacity 0.2s ease-in-out",
          }}
        />
      </Box>
      <Popover
        tabIndex={0}
        open={Boolean(anchorEl) && !disabled}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            boxShadow: "0px 0px 2px 0px #0000001F, 0px 4px 8px 0px #00000024",
            border: "1px solid #EEEEEE",
          },
        }}
        slotProps={{
          paper: {
            role: "listbox",
            "aria-modal": true,
            "aria-label": "country-select",
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Stack
            sx={{
              boxSizing: "border-box",
              backgroundColor: "#FFFFFF",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#DFDFDF",
              borderRadius: "0.25rem",
              boxShadow: "0px 0px 8px 0px #0000001F, 0px 4px 8px 0px #00000024",
              width: "312px",
              height: "184px",
            }}
          >
            <Stack
              height="44px"
              paddingX="16px"
              alignItems="center"
              justifyContent="center"
            >
              <SearchBar
                size="small"
                placeholder="Search country code or name"
                onChange={handleSearchChange}
                containerProps={{
                  sx: {
                    width: "100%",
                    maxWidth: "100%",
                  },
                }}
              />
            </Stack>
            <Stack height="140px" overflow="auto">
              {filteredCountries.length < 1 ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  paddingX="16px"
                  height="35px"
                  minHeight="35px"
                  gap="32px"
                >
                  <Typography
                    component="span"
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight="1.43"
                    color="#242d35"
                  >
                    No results found
                  </Typography>
                </Stack>
              ) : (
                filteredCountries
                  .filter((option) => option.value)
                  .map((option, index) => (
                    <Stack
                      key={index}
                      aria-label={option.label}
                      role="option"
                      direction="row"
                      alignItems="center"
                      paddingX="16px"
                      height="35px"
                      minHeight="35px"
                      gap="32px"
                      onClick={() =>
                        handleCountrySelect(option.value, option.callingCode)
                      }
                      sx={{
                        backgroundColor:
                          value === option.value ? "#DCEBF3" : "#FFFFFF",
                        cursor: "pointer",
                        transition: "background-color 0.2s ease-in-out",
                        ":hover": {
                          backgroundColor:
                            value === option.value ? "#DCEBF3" : "#EEEEEE",
                        },
                        ":focus": {
                          backgroundColor:
                            value === option.value ? "#DCEBF3" : "#DEDEDE",
                        },
                      }}
                    >
                      <Stack
                        direction="row"
                        gap="8px"
                        alignItems="center"
                        fontSize="14px"
                        minWidth="61px"
                      >
                        <Icon
                          label={value}
                          aria-hidden
                          country={option.value}
                        />
                        <Typography
                          component="span"
                          fontSize="14px"
                          fontWeight={400}
                          lineHeight="1.43"
                          color="#242d35"
                        >
                          {isSupportedCountry(option.value)
                            ? `+${getCountryCallingCode(option.value)}`
                            : null}
                        </Typography>
                      </Stack>
                      <TextWithTooltip
                        text={option.label}
                        limit={20}
                        textStyle={{
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "1.43",
                          color: "#242d35",
                        }}
                        tooltipProps={{
                          arrow: true,
                          placement: "top",
                        }}
                      />
                    </Stack>
                  ))
              )}
            </Stack>
          </Stack>
        </ClickAwayListener>
      </Popover>
    </Fragment>
  )
}
