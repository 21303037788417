export function filterEmojis(text: string): string {
  const regex =
    /(\p{Extended_Pictographic}|\p{Modifier_Symbol}|\p{Variation_Selector}|\u200D)/gu
  if (text.match(regex)) {
    text = text.replaceAll(regex, "")
  }

  return text
}

export default filterEmojis
