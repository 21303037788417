import {
  PresentationIcon,
  TrainingIcon,
  PhoneCallIcon,
  OtherIcon,
  WebResportIcon,
} from "../../../../assets/icons"

export const deliveryType = [
  {type: "Report", icon: WebResportIcon},
  {type: "Presentation", icon: PresentationIcon},
  {type: "Training", icon: TrainingIcon},
  {type: "Phone call", icon: PhoneCallIcon},
  {type: "Other", icon: OtherIcon},
]

export const formatCurrency = (value: string) => {
  if (value === "") return ""

  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
  })

  return `USD ${formatter.format(Number(value))}`
}

//Time zone
type TimeZoneInfo = {
  offset: number
  description: string
}

const getTimeZoneOffsetString = (offsetMinutes: number): string => {
  const sign = offsetMinutes >= 0 ? "+" : "-"
  const absOffsetMinutes = Math.abs(offsetMinutes)
  const hours = String(Math.floor(absOffsetMinutes / 60)).padStart(2, "0")
  const minutes = String(absOffsetMinutes % 60).padStart(2, "0")
  return `UTC${sign}${hours}:${minutes}`
}

const getOffsetForTimeZone = (timeZone: string): number => {
  const now = new Date()
  const utcDate = new Date(now.toLocaleString("en-US", {timeZone: "UTC"}))
  const tzDate = new Date(now.toLocaleString("en-US", {timeZone}))
  const offset = (tzDate.getTime() - utcDate.getTime()) / (1000 * 60) // Offset in minutes
  return offset
}

const formatTimeZoneName = (timeZone: string): string => {
  return timeZone.replace(/\//g, ", ").replace(/_/g, " ")
}

const getDescriptionForTimeZone = (timeZone: string): TimeZoneInfo => {
  const offsetMinutes = getOffsetForTimeZone(timeZone)
  const offsetString = getTimeZoneOffsetString(offsetMinutes)
  const formattedTimeZone = formatTimeZoneName(timeZone)
  return {
    offset: offsetMinutes,
    description: `(${offsetString}) ${formattedTimeZone}`,
  }
}

export const generateSupportedTimeZones = (): string[] => {
  const timeZones = Intl.supportedValuesOf("timeZone")
  const timeZoneInfoArray: TimeZoneInfo[] = timeZones.map(
    getDescriptionForTimeZone,
  )

  timeZoneInfoArray.sort((a, b) => a.offset - b.offset)

  return timeZoneInfoArray.map((info) => info.description)
}

export const getBrowserTimeZoneDescription = (): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneInfo = getDescriptionForTimeZone(timeZone)
  return timeZoneInfo.description
}
