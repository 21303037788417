import {FC, createContext, useContext} from "react"
import {ConfigProviderProps, GlobalConfig} from "./types"
import {isJsonString} from "../helpers"

const config = {
  serverUrl: process.env.REACT_APP_FUNCTION_APP_API || "%%FUNCTION_APP_API%%",
  notificationUrl:
    process.env.REACT_APP_NOTIFICATION_API || "%%NOTIFICATION_API%%",
  tableauServerUrl:
    process.env.REACT_APP_TABLEAU_SERVER || "%%tableauServerUrl%%",
  faToken: process.env.REACT_APP_FADEFAULT || "%%FADEFAULT%%",
  // deepcode ignore HardcodedNonCryptoSecret: False positive caused by the variable name
  apimKey: process.env.REACT_APP_APIM_KEY || "%%apimKey%%",
  notificationFaToken:
    process.env.REACT_APP_FADEFAULT_NOTIFICATION ||
    "%%FADEFAULT_NOTIFICATION%%",
  apimKeyNotification:
    process.env.REACT_APIM_KEY_NOTIFICATION || "%%apimKeyNotification%%",
  connectionString:
    process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRIN ||
    "%%appInsightsConnectionString%%",
  apryseKey: process.env.REACT_APP_APPRYSE_KEY || "%%APPRYSE_KEY%%",
  userProfileServerUrl:
    process.env.REACT_APP_USER_PROFILE_API || "%%USER_PROFILE_API%%",
  userProfileApimKey:
    process.env.REACT_APIM_KEY_USER_PROFILE || "%%APIM_KEY_USER_PROFILE%%",
  userProfileFaToken:
    process.env.REACT_APP_FADEFAULT_USER_PROFILE ||
    "%%FADEFAULT_USER_PROFILE%%",
  accountServerUrl: process.env.REACT_APP_ACCOUNT_API || "%%ACCOUNT_API%%",
  apimKeyAcct: process.env.REACT_ACCOUNT_APIM_KEY || "%%ACCOUNT_APIM_KEY%%",
  faTokenAcct:
    process.env.REACT_APP_ACCOUNT_FADEFAULT || "%%ACCOUNT_FADEFAULT%%",
  languages: isJsonString(process.env.LANGUAGES || "%%LANGUAGES%%")
    ? JSON.parse(process.env.LANGUAGES || "%%LANGUAGES%%")
    : [],
}
const MyContext = createContext({} as GlobalConfig)

export const useConfigProvider = () => {
  const context = useContext(MyContext)
  if (!context) {
    throw new Error("useMyContext must be used inside a ConfigProvider")
  }
  return context
}

export const ConfigProvider: FC<ConfigProviderProps> = ({children, value}) => {
  return (
    <MyContext.Provider value={{...config, ...value}}>
      {children}
    </MyContext.Provider>
  )
}
