import {Stack, useTheme} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {FC, SyntheticEvent, useLayoutEffect, useRef, useState} from "react"
import {
  CalendarIcon,
  ChevronDownIcon,
  LeftArrowIcon,
  RightArrowIcon,
} from "../../../../assets/icons"

type CustomDatePickerProps = {
  onChange: (e: SyntheticEvent) => void
  onBlur?: (e: SyntheticEvent) => void
  value?: any
  error?: string
  maxDate?: Date
  minDate?: Date
}

export const CustomDatePicker: FC<CustomDatePickerProps> = (props) => {
  const {onChange, onBlur, value, error, maxDate, minDate} = props
  const theme = useTheme()
  const onBlurRef = useRef(onBlur)
  const [open, setOpen] = useState(false)

  useLayoutEffect(() => {
    onBlurRef.current = onBlur
  })

  return (
    <Stack onBlur={(e) => onBlurRef.current?.(e)}>
      <DatePicker
        value={value}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        format="dd/MM/yyyy"
        maxDate={maxDate}
        minDate={minDate}
        disableHighlightToday
        onChange={(newValue) => onChange(newValue)}
        sx={{display: "none"}}
        slots={{
          openPickerIcon: CalendarIcon,
          switchViewIcon: () => <ChevronDownIcon fill="#053747" />,
          leftArrowIcon: LeftArrowIcon,
          rightArrowIcon: RightArrowIcon,
        }}
        slotProps={{
          textField: {
            placeholder: "dd/mm/yyyy",
            sx: {
              ".MuiInputBase-root": {
                fontSize: "14px",
              },
              ".MuiInputBase-input": {
                p: "10px 14px",
              },
              ".MuiInputAdornment-root": {
                height: "16px",
                width: "21px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: error
                  ? theme.palette.error.main
                  : theme.palette.primary.contrastText,
              },
              textTransform: "lowercase",
            },
            onClick: () => setOpen(true),
          },
          layout: {
            sx: {
              backgroundColor: "#fff",
            },
          },
          day: {
            sx: {
              "&:hover": {backgroundColor: "#226ca6c2", color: "#fff"},
              "&:focus": {backgroundColor: "#226CA6", color: "#fff"},
              "&.Mui-selected": {
                backgroundColor: "#226CA6",
                color: "#fff",
              },
              "&.Mui-selected:hover": {
                willChange: "unset",
                backgroundColor: "#226ca6c2",
                color: "#fff",
              },
              "&:focus.Mui-selected": {
                willChange: "unset",
                backgroundColor: "#226ca6c2",
                color: "#fff",
              },
            },
          },
          calendarHeader: {
            sx: {
              "& .MuiPickersArrowSwitcher-spacer": {
                width: "50px",
              },
            },
          },
        }}
      />
    </Stack>
  )
}

CustomDatePicker.displayName = "DatePicker"

export default CustomDatePicker
