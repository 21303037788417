import {FC, useEffect} from "react"
import {Box, Paper, Stack, Typography} from "@mui/material"
import Button from "../../lib/components/atoms/button/Button"
import Footer from "../../lib/components/organisms/Footer/Footer"
import {ReactSVG} from "react-svg"

type Props = {
  message?: string
}

const SessionExpired: FC<Props> = (props) => {
  const {message = "Your session has expired"} = props

  useEffect(() => {
    sessionStorage.clear()
    window.history.replaceState({}, document.title, "/")
  }, [])

  const handleLogOut = () => {
    window.location.href = "/"
  }

  return (
    <Box
      sx={{
        height: "100dvh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#CCD8DC",
      }}
    >
      <style>
        {`
          #main {
            margin-top: 0 !important;
            height: 100vh !important;
          }
        `}
      </style>
      <Box
        flex={1}
        display={"flex"}
        height={"100%"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          flex={1}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Paper
            elevation={2}
            sx={{
              backgroundColor: "#fff",
              paddingY: 4,
              paddingX: 24,
              transform: "translateY(-25%)",
            }}
          >
            <Stack textAlign={"center"} gap={3}>
              <Stack gap={1}>
                <ReactSVG
                  src="/icons/session-expired.svg"
                  style={{fill: "#BA0E68"}}
                />
                <Typography fontWeight={"bold"} fontSize={16} color={"#242D35"}>
                  {message}
                </Typography>
              </Stack>
              <Button color="#053747" onClick={handleLogOut}>
                Go back to login
              </Button>
            </Stack>
          </Paper>
        </Box>
        <Footer sx={{pl: 2, bottom: 0, width: "100%"}} />
      </Box>
    </Box>
  )
}

export default SessionExpired
