import {useMsal} from "@azure/msal-react"
import {IconButton, Stack, Typography, ClickAwayListener} from "@mui/material"
import {Fragment, useEffect, useState, type FC} from "react"
import {decodeToken} from "react-jwt"
import {useNavigate} from "react-router-dom"
import {ReactSVG} from "react-svg"
import {DecodedToken} from "../../../types/Token"
import SwitchAccount from "../organisms/SwitchAccount/SwitchAccount"
import Menu from "./Menu"
import ConfirmationDialog from "./confirmationDialog/ConfirmationDialog"
import {StorageData} from "../../../hooks/useStorage"
import LanguageDropdown from "../organisms/LanguageDropdown/LanguageDropdown"
import {useTranslation} from "react-i18next"
import {useLocalStorage} from "usehooks-ts"

type WelcomeProps = {
  token: string
  showAccountPanel: boolean
  setShowAccountPanel?: (showAccountPanel: boolean) => void
  setRun: (run: boolean) => void
  setCompletedTour: (run: boolean) => void
}

const Welcome: FC<WelcomeProps> = (props) => {
  const {
    showAccountPanel,
    setShowAccountPanel,
    setRun,
    setCompletedTour,
    token,
  } = props
  const [showLogoutDialog, setShowLogoutDialog] = useState<boolean>(false)
  const [forceNavigation, setForceNavigation] = useState<boolean>(false)
  const [isRotated, setIsRotated] = useState<boolean>(false)
  const {accounts, instance} = useMsal()
  const decoded = decodeToken<DecodedToken>(token)
  const [name] = useLocalStorage("name", decoded?.name)
  const [selectedAccount, setSelectedAccount] = useLocalStorage("account", "")
  const navigate = useNavigate()
  const {t} = useTranslation()

  const handleAccountPanel = () => {
    if (setShowAccountPanel) {
      setShowAccountPanel(true)
    }
    setForceNavigation(true)
  }

  const handleLogOut = () => {
    sessionStorage.clear()
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: "/",
    }
    if (selectedAccount) {
      setSelectedAccount("")
    }
    instance.logoutRedirect(logoutRequest)
  }

  const handleOpenLogoutDialog = () => {
    setShowLogoutDialog(true)
  }

  const handleRotationToggle = () => {
    setIsRotated(!isRotated)
  }

  useEffect(() => {
    window.addEventListener("logout", handleOpenLogoutDialog)

    return () => {
      window.removeEventListener("logout", handleOpenLogoutDialog)
    }
  }, [])

  return (
    <Stack direction="row">
      <Fragment />
      <LanguageDropdown />
      <Stack
        id="user-welcome"
        direction="row"
        gap="0.5rem"
        alignItems="center"
        paddingX="44px"
        paddingY="12px"
      >
        <Typography
          fontSize="0.875rem"
          fontStyle="normal"
          fontWeight={400}
          lineHeight="1.5rem"
          color="#262637"
        >
          {t("Welcome")}, <b>{name}</b>
        </Typography>
        <Menu
          items={
            decoded?.email?.includes("@controlrisks.com") ||
            decoded?.companyId === "001"
              ? [
                  {
                    icon: <ReactSVG src="/icons/user.svg" />,
                    text: t("Your account"),
                    onClick: () => navigate("/profile"),
                  },
                  {
                    icon: <ReactSVG src="/icons/switch-account.svg" />,
                    text: t("Switch account"),
                    onClick: () => handleAccountPanel(),
                  },
                  {
                    icon: <ReactSVG src="/icons/footsteps.svg" />,
                    text: t("Guided tour"),
                    onClick: () => {
                      setCompletedTour(false)
                      setRun(true)
                      localStorage.setItem(StorageData.completedTour, "")
                      navigate("/")
                    },
                  },
                  {
                    icon: <ReactSVG src="/icons/log-out.svg" />,
                    text: t("Log out"),
                    onClick: () => setShowLogoutDialog(true),
                  },
                ]
              : [
                  {
                    icon: <ReactSVG src="/icons/user.svg" />,
                    text: t("Your account"),
                    onClick: () => navigate("/profile"),
                  },
                  {
                    icon: <ReactSVG src="/icons/footsteps.svg" />,
                    text: t("Guided tour"),
                    onClick: () => {
                      setCompletedTour(false)
                      setRun(true)
                      localStorage.setItem("completedTour", "")
                      navigate("/")
                    },
                  },
                  {
                    icon: <ReactSVG src="/icons/log-out.svg" />,
                    text: t("Log out"),
                    onClick: () => setShowLogoutDialog(true),
                  },
                ]
          }
          Trigger={(props) => (
            <IconButton
              size="large"
              sx={{
                padding: 0,
                backgroundColor: "unset",
                boxShadow: "unset",
                height: "1.25rem",
                width: "1.25rem",
                "&:hover": {
                  backgroundColor: "unset",
                  boxShadow: "unset",
                },
                "div>div": {
                  height: "1.25rem",
                  width: "1.25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                svg: {
                  height: "0.625rem",
                  width: "1.25rem",
                },
              }}
              {...props}
            >
              <ClickAwayListener onClickAway={() => setIsRotated(false)}>
                <ReactSVG
                  src="/icons/chevron-down.svg"
                  onClick={handleRotationToggle}
                  style={{
                    transform: isRotated ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </ClickAwayListener>
            </IconButton>
          )}
        />
      </Stack>
      <ConfirmationDialog
        showDialog={showLogoutDialog}
        title={t("Log out")}
        message={t("Are you sure you want to log out?")}
        handleClose={() => setShowLogoutDialog(false)}
        handleConfirm={handleLogOut}
        closeText={t("Cancel")}
        confirmText={t("Log out")}
      />
      {decoded?.companyId === "001" ? (
        <SwitchAccount
          showAccountPanel={showAccountPanel}
          setShowAccountPanel={setShowAccountPanel || (() => {})}
          forceNavigation={forceNavigation}
        />
      ) : null}
    </Stack>
  )
}

export default Welcome
