import {type TooltipProps, Tooltip as MuiTooltip, useTheme} from "@mui/material"
import {FC} from "react"

// export const Tooltip = styled(({className, ...props}: TooltipProps) => (
//   <MuiTooltip {...props} classes={{popper: className}} />
// ))(({theme}) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     boxShadow: shadows({boxShadow: 1}),
//     fontSize: "0.875rem",
//     fontWeight: "normal",
//     textAlign: "center",
//     padding: "0.5rem 0.75rem",
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: theme.palette.primary.main,
//   },
// }))

export const Tooltip: FC<TooltipProps> = (props) => {
  const {sx, ...rest} = props
  const theme = useTheme()
  return (
    <MuiTooltip
      {...rest}
      sx={{
        ".MuiTooltip-tooltip": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          boxShadow: 1,
          fontSize: "0.875rem",
          fontWeight: "normal",
          textAlign: "center",
          padding: "0.5rem 0.75rem",
        },
        ".MuiTooltip-arrow": {
          color: theme.palette.primary.main,
        },
        ...sx,
      }}
    />
  )
}
