// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html:root {
  --toastify-text-color-light: #595958;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body:has(div#loading-screen) {
  overflow: hidden;
}

.toast {
  border-left-width: 8px;
  border-left-style: solid;
  border-left-color: #db0d00;
  color: #595958;
}

.toast .toast-body {
  align-items: flex-start;
  gap: 1rem;
}

.toast .toast-body>div:first-child {
  margin: 0;
}

.toast .toast-body>div:last-child {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #cacdcd;
}`, "",{"version":3,"sources":["webpack://./src/styles/root.css"],"names":[],"mappings":"AAEA;EACE,oCAAoC;AACtC;AACA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,sDAAsD;EACtD,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,wBAAwB;EACxB,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,2BAA2B;AAC7B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap\");\r\n\r\nhtml:root {\r\n  --toastify-text-color-light: #595958;\r\n}\r\n* {\r\n  margin: 0;\r\n  padding: 0;\r\n  box-sizing: border-box;\r\n}\r\n\r\nbody {\r\n  font-family: \"Open Sans\", Arial, Helvetica, sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\nbody:has(div#loading-screen) {\r\n  overflow: hidden;\r\n}\r\n\r\n.toast {\r\n  border-left-width: 8px;\r\n  border-left-style: solid;\r\n  border-left-color: #db0d00;\r\n  color: #595958;\r\n}\r\n\r\n.toast .toast-body {\r\n  align-items: flex-start;\r\n  gap: 1rem;\r\n}\r\n\r\n.toast .toast-body>div:first-child {\r\n  margin: 0;\r\n}\r\n\r\n.toast .toast-body>div:last-child {\r\n  border-right-width: 1px;\r\n  border-right-style: solid;\r\n  border-right-color: #cacdcd;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
