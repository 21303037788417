import {Fragment, MouseEventHandler, type FC, useEffect, useState} from "react"
import {ReactSVG} from "react-svg"
import {navigateToUrl} from "single-spa"
import {
  AppBar,
  Box,
  Link,
  LinkProps,
  Stack,
  Toolbar,
  useTheme,
} from "@mui/material"
import {LayoutObject} from "../types"
import Logo from "../assets/MyCRLogo.png"
import {Welcome} from "./components/molecules"
import {AskAnExpertModal, OnboardingModal} from "./components/organisms"
import {useTranslation} from "react-i18next"
import {useLocalStorage} from "usehooks-ts"

const handleNavigate: MouseEventHandler<HTMLAnchorElement> = (event) => {
  const url = new URL(event.currentTarget.href)
  const path = url.pathname

  const clickNavButtonEvent = new CustomEvent(
    "mycr-navigation:navButtonClicked",
    {
      detail: {clicked: true, path: path},
    },
  )

  event.preventDefault()
  window.dispatchEvent(clickNavButtonEvent)
  navigateToUrl(event.currentTarget.href)
}

const getLinkProps = (
  href: string,
  path: string,
  onClick?: () => void,
): LinkProps => ({
  href,
  onClick: (event) => {
    handleNavigate(event)
    if (onClick) onClick()
  },
})

type NavigationProps = {
  token: string
  showAccountPanel: boolean
  setShowAccountPanel: (showAccountPanel: boolean) => void
  setRun: (run: boolean) => void
  setCompletedTour: (completedTour: boolean) => void
  paths: LayoutObject[]
}

const Navigation: FC<NavigationProps> = (props) => {
  const {
    showAccountPanel,
    setShowAccountPanel,
    setRun,
    setCompletedTour,
    token,
    paths,
  } = props
  const theme = useTheme()
  const [path, setPath] = useState(window.location.pathname)
  const activeTab = path.startsWith("/projects") ? "/projects" : "/"

  const [language] = useLocalStorage("language", "en")
  const {i18n, t} = useTranslation()

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language)
    }
  }, [language])

  useEffect(() => {
    window.addEventListener("single-spa:routing-event", () => {
      setPath(window.location.pathname)
    })

    return () => {
      window.removeEventListener("single-spa:routing-event", () => {
        setPath(window.location.pathname)
      })
    }
  }, [])

  return (
    <Fragment>
      <AppBar
        position="fixed"
        component="header"
        sx={{padding: "0 !important"}}
      >
        <Toolbar
          component="nav"
          sx={{
            backgroundColor: theme.palette.common.white,
            color: "#262637",
            height: "3rem",
            justifyContent: "space-between",
            minHeight: "unset !important",
            paddingX: {
              md: "2.625rem",
            },
          }}
        >
          <Link
            {...getLinkProps("/", path)}
            underline="none"
            sx={{lineHeight: 0}}
          >
            <img src={Logo} alt="MyCR Logo" height="31px" />
          </Link>
          <Welcome
            token={token}
            showAccountPanel={showAccountPanel}
            setShowAccountPanel={setShowAccountPanel}
            setRun={setRun}
            setCompletedTour={setCompletedTour}
          />
        </Toolbar>
        {/* <NavigationTopBar/> */}
        <Stack
          component="nav"
          direction="row"
          sx={{
            boxSizing: "border-box",
            backgroundColor: "#053747",
            color: theme.palette.common.white,
            alignItems: "center",
            justifyContent: "flex-start",
            minHeight: "unset",
            height: "2.5rem",
          }}
        >
          {paths.map((link) =>
            link.title ? (
              <Link
                id={`${link.title.toLowerCase()}-link`}
                key={link.path}
                {...getLinkProps(link.path, path)}
                sx={{
                  boxSizing: "border-box",
                  paddingX: "2rem",
                  paddingY: "0.5rem",
                  fontWeight:
                    link.path === activeTab || paths.length === 1
                      ? "bold"
                      : "normal",
                  color: "inherit",
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  borderBottomWidth: "4px",
                  borderBottomStyle: "solid",
                  borderBottomColor:
                    link.path === activeTab || paths.length === 1
                      ? "#698791"
                      : "transparent",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexGrow: 0,
                  height: "2.5rem",
                }}
                underline="none"
              >
                {link.icon ? (
                  <Box
                    sx={{
                      div: {
                        height: "24px",
                      },
                    }}
                  >
                    <ReactSVG src={link.icon} style={{marginRight: "10px"}} />
                  </Box>
                ) : null}
                {t(link.title)}
              </Link>
            ) : null,
          )}
        </Stack>
      </AppBar>
      <OnboardingModal />
      <AskAnExpertModal />
    </Fragment>
  )
}

export default Navigation
