import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import en from "../locales/en.json"
import ja from "../locales/ja.json"
import es from "../locales/es.json"
import fr from "../locales/fr.json"
import de from "../locales/de.json"
import pt_BR from "../locales/pt_BR.json"

i18n.use(initReactI18next).init({
  defaultNS: "common",
  fallbackLng: "en",
  resources: {
    en: {common: en},
    ja: {common: ja},
    es: {common: es},
    fr: {common: fr},
    de: {common: de},
    pt_BR: {common: pt_BR},
  },
})

export default i18n
