import {Popover} from "@mui/material"
import {FC, Fragment, useState} from "react"
import {useLocalStorage} from "usehooks-ts"
import {ChevronDownIcon} from "../../../../assets/icons"
import {useConfigProvider} from "../../../../config"
import {NewButton} from "../../atoms"

const LanguageDropdown: FC = () => {
  const [language, setLanguage] = useLocalStorage("language", "en")
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const open = Boolean(anchorEl)
  const {languages} = useConfigProvider()

  if (!languages || languages.length === 0) return null
  return (
    <Fragment>
      <NewButton
        text={languages.find((lang) => lang.code === language)?.name}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant="text"
        size="medium"
        color="primary"
        IconRight={<ChevronDownIcon />}
        sx={{
          marginRight: "1rem",
          marginLeft: "auto",
          paddingY: "4px",
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            boxShadow: "0px 0px 2px 0px #0000001F, 0px 4px 8px 0px #00000024",
            border: "1px solid #EEEEEE",
          },
        }}
        slotProps={{
          paper: {
            role: "listbox",
            "aria-modal": true,
            "aria-label": "country-select",
          },
        }}
      >
        {languages.map((lang) => (
          <NewButton
            key={lang.code}
            text={lang.name}
            onClick={() => {
              setAnchorEl(null)
              setLanguage(lang.code)
            }}
            variant="text"
            size="medium"
            color="secondary"
          />
        ))}
      </Popover>
    </Fragment>
  )
}

export default LanguageDropdown
