import {Box, Typography} from "@mui/material"

export const TCs = () => {
  return (
    <Box>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        This End User License Agreement ("Agreement") is a binding agreement
        between you ("End User" or "you") and Control Risks Ltd ("Company").
        This Agreement governs your use of the Control Risks Insights Portal,
        (including all related documentation, the "Application"). The
        Application is licensed, not sold, to you.
      </Typography>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        BY CLICKING THE "AGREE" BUTTON YOU:
        <br />
        (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT.
        <br />
        (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING
        AGREEMENT.
        <br />
        (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS
        TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT PROCEED.
        <br />
      </Typography>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        <br />
        1. License Grant. Subject to the terms of this Agreement, Company grants
        you a limited, non-exclusive, and non-transferable license to: <br />
        <br />
        (a) access the Application for your personal, non-commercial use
        strictly in accordance with the Application's documentation.
        <br />
        (b) access the Content and Services made available in or otherwise
        accessible through the Application, strictly in accordance with this
        Agreement and the Terms of Use applicable.
        <br />
      </Typography>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        <br />
        2. License Restrictions. You shall not: <br />
        (a) share username, password or authentication credentials with others.
        All access is granted by named user – contact your Control Risks account
        manager to grant access to additional users. <br />
        (b) modify, translate, adapt, or otherwise create derivative works or
        improvements, whether or not patentable, of the Application or any
        content accessed through the Application; <br />
        (c) reverse engineer, disassemble, decompile, decode, or otherwise
        attempt to derive or gain access to the source code of the Application
        or any part thereof; <br />
        (d) remove, delete, alter, or obscure any trademarks or any copyright,
        trademark, patent, or other intellectual property or proprietary rights
        notices from the Application, including any copy thereof;
        <br />
        (e) rent, lease, lend, sell, sublicense, assign, distribute, publish,
        transfer, or otherwise make available the Application, or any features
        or functionality of the Application, to any third party for any reason,
        including by making the Application available on a network where it is
        capable of being accessed by more than one device at any time <br />
        (f) remove, disable, circumvent, or otherwise create or implement any
        workaround to any copy protection, rights management, or security
        features in or protecting the Application
      </Typography>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        <br />
        3. Reservation of Rights. You acknowledge and agree that the Application
        is provided under license, and not sold, to you. You do not acquire any
        ownership interest in the Application under this Agreement, or any other
        rights thereto other than to use the Application in accordance with the
        license granted, and subject to all terms, conditions, and restrictions,
        under this Agreement. Company reserves and shall retain its entire
        right, title, and interest in and to the Application, including all
        copyrights, trademarks, and other intellectual property rights therein
        or relating thereto, except as expressly granted to you in this
        Agreement.
      </Typography>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        <br />
        4. Collection and Use of Your Information. You acknowledge that when you
        download, install, or use the Application, Company may use automatic
        means (including, for example, cookies and web beacons) to collect
        information about your Device and about your use of the Application. You
        also may be required to provide certain information about yourself as a
        condition to using the Application or certain of its features or
        functionality. All information we collect through or in connection with
        this Application is subject to our Privacy Policy (
        <a
          href="https://www.controlrisks.com/legal-1/privacy-policy"
          target="blank"
        >
          https://www.controlrisks.com/legal-1/privacy-policy
        </a>
        ). By downloading, installing, using, and providing information to or
        through this Application, you consent to all actions taken by us with
        respect to your information in compliance with the Privacy Policy.
      </Typography>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        <br />
        5. Updates. Company may from time to time in its sole discretion develop
        and provide Application updates, which may include upgrades, bug fixes,
        patches, other error corrections, and/or new features (collectively,
        including related documentation, "Updates"). Updates may also modify or
        delete in their entirety certain features and functionality. You agree
        that Company has no obligation to provide any Updates or to continue to
        provide or enable any particular features or functionality.
      </Typography>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        <br />
        6. Third-Party Materials. The Application may display, include, or make
        available third-party content (including data, information,
        applications, and other products, services, and/or materials) or provide
        links to third-party websites or services, including through third-party
        advertising ("Third-Party Materials"). You acknowledge and agree that
        Company is not responsible for Third-Party Materials, including their
        accuracy, completeness, timeliness, validity, copyright compliance,
        legality, decency, quality, or any other aspect thereof. Company does
        not assume and will not have any liability or responsibility to you or
        any other person or entity for any Third-Party Materials. Third-Party
        Materials and links thereto are provided solely as a convenience to you,
        and you access and use them entirely at your own risk and subject to
        such third parties' terms and conditions.
      </Typography>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        <br />
        7. Disclaimer of Warranties: The application is provided to end user "as
        is" and with all faults and defects without warranty of any kind. To the
        maximum extent permitted under applicable law, company, on its own
        behalf and on behalf of its affiliates and its and their respective
        licensors and service providers, expressly disclaims all warranties,
        whether express, implied, statutory, or otherwise, with respect to the
        application, including all implied warranties of merchantability,
        fitness for a particular purpose, title, and non-infringement, and
        warranties that may arise out of course of dealing, course of
        performance, usage, or trade practice. Without limitation to the
        foregoing, company provides no warranty or undertaking, and makes no
        representation of any kind that the application will meet your
        requirements, achieve any intended results, be compatible, or work with
        any other software, applications, systems, or services, operate without
        interruption, meet any performance or reliability standards, or be
        error-free, or that any errors or defects can or will be corrected. Some
        jurisdictions do not allow the exclusion of or limitations on implied
        warranties or the limitations on the applicable statutory rights of a
        consumer, so some or all of the above exclusions and limitations may not
        apply to you.
      </Typography>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        <br />
        8. Limitation of Liability: To the fullest extent permitted by
        applicable law, in no event will company or its affiliates, or any of
        its or their respective licensors or service providers, have any
        liability arising from or related to your use of or inability to use the
        application or the content and services for: <br />
        (a) personal injury, property damage, lost profits, cost of substitute
        goods or services, loss of data, loss of goodwill, business
        interruption, computer failure or malfunction, or any other
        consequential, incidental, indirect, exemplary, special, or punitive
        damages. <br />
        (b) direct damages in amounts that in the aggregate exceed the amount
        actually paid by you for the application. The foregoing limitations will
        apply whether such damages arise out of breach of contract, tort
        (including negligence), or otherwise and regardless of whether such
        damages were foreseeable, or company was advised of the possibility of
        such damages. Some jurisdictions do not allow certain limitations of
        liability so some or all of the above limitations of liability may not
        apply to you.
      </Typography>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        <br />
        9. Indemnification. You agree to indemnify, defend, and hold harmless
        Company and its officers, directors, employees, agents, affiliates,
        successors, and assigns from and against any and all losses, damages,
        liabilities, deficiencies, claims, actions, judgments, settlements,
        interest, awards, penalties, fines, costs, or expenses of whatever kind,
        including reasonable attorneys' fees, arising from or relating to your
        use or misuse of the Application or your breach of this Agreement.
      </Typography>
      <Typography fontSize="16px" lineHeight="1.5" fontWeight="400">
        <br />
        10. Severability. If any provision of this Agreement is illegal or
        unenforceable under applicable law, the remainder of the provision will
        be amended to achieve as closely as possible the effect of the original
        term and all other provisions of this Agreement will continue in full
        force and effect.
      </Typography>
    </Box>
  )
}
