export const multilineStringIsLink =
  /https?:\/\/|www\.\S+|(?<!\w)\.\S{2,}|(?<!https?:\/\/|www\.)\b\S*\.[a-zA-Z]{2,}\b/im

export const stringIsScript = /^(?!.*<script>.*?<\/script>).*$/is

export const alphaNumericRegex = /^[a-zA-Z0-9]*$/
export const stringIsNotLink = /^(?!.*\.\S).*[\w .,\\+]+$/
export const stringIsLink =
  /^(?!.*?https?:\/\/|www\.)(?!.*?\.(com|org|net|gov|edu|int|mil|arpa|info|biz|name|pro|aero|coop|museum|jobs|travel|[a-z]{2})($|\/))([\s\S]*)/gi
export const stringIsProjectCode = /^[0-9.]+$/
export const stringIsNotSQL =
  /^(?!SELECT |UPDATE |CREATE |DROP |DELETE )[\s\S]*$/i

export function regexValidators() {
  return {
    alphaNumericRegex,
    stringIsNotLink,
    stringIsLink,
    stringIsScript,
    stringIsProjectCode,
    stringIsNotSQL,
  }
}
