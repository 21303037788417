import {type FC, Fragment, type CSSProperties, ChangeEvent} from "react"
import {
  InputLabel,
  Stack,
  useTheme,
  Typography,
  type TextFieldProps,
  type StackProps,
  TextField,
} from "@mui/material"
import {Warning} from "@mui/icons-material"
import {FormGroup, Tooltip} from "../"
import {filterEmojis} from "../../../../helpers"
import {useTranslation} from "react-i18next"

type CounterProps = {
  label?: string
  required?: boolean
  requiredStyle?: CSSProperties
  counter: true
  limit: number
  error?: string
  textFieldProps: TextFieldProps
  formGroupProps?: StackProps
  disabled?: boolean
  ellipsis?: boolean
  labelStyle?: CSSProperties
}

type BaseProps = {
  label?: string
  required?: boolean
  requiredStyle?: CSSProperties
  counter?: false
  limit?: undefined
  error?: string
  textFieldProps: TextFieldProps
  formGroupProps?: StackProps
  disabled?: boolean
  ellipsis?: boolean
  labelStyle?: CSSProperties
}

type Props = CounterProps | BaseProps

const Input: FC<Props> = (props) => {
  const {
    label,
    required,
    error,
    counter,
    limit,
    textFieldProps,
    formGroupProps,
    requiredStyle,
    disabled,
    ellipsis,
    labelStyle,
  } = props
  const theme = useTheme()
  const {t} = useTranslation()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = filterEmojis(e.target.value)

    if (textFieldProps?.onChange) {
      textFieldProps.onChange(e)
    }
  }

  return (
    <FormGroup {...formGroupProps}>
      {label ? (
        <InputLabel
          htmlFor={textFieldProps?.id || textFieldProps?.name}
          sx={{
            color: error
              ? "#DB0D00"
              : labelStyle?.color
              ? labelStyle?.color
              : theme.palette.primary.main,
            fontWeight: 400,
            fontSize: "0.875rem",
          }}
        >
          {t(label)}
          {required ? (
            <span
              style={{
                color: "#DB0D00",
                ...requiredStyle,
              }}
            >
              {" "}
              *
            </span>
          ) : null}
        </InputLabel>
      ) : null}
      {textFieldProps ? (
        <Tooltip
          title={
            ellipsis &&
            textFieldProps?.value?.toString() &&
            textFieldProps?.value?.toString().length > 35
              ? t(textFieldProps?.value?.toString())
              : ""
          }
        >
          <TextField
            {...textFieldProps}
            onChange={handleChange}
            inputProps={{readOnly: disabled ? true : false}}
            sx={{
              backgroundColor: disabled ? "#CACDCD" : null,
              ".MuiInputBase-input": {
                padding: "0.5rem",
                borderRadius: "0.25rem",
                transition:
                  "color 0.2s ease-in-out, border 0.2s ease-in-out, background 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out",
                overflow: ellipsis ? "hidden" : null,
                textOverflow: ellipsis ? "ellipsis" : null,
              },
              ".MuiOutlinedInput-input": {
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: error
                  ? "#DB0D00"
                  : theme.palette.primary.contrastText,

                "&:hover": {
                  borderColor: theme.palette.grey[500],
                },

                "&:focus": {
                  borderColor: theme.palette.primary.main,
                },
              },
              ".Mui-disabled": {
                backgroundColor: theme.palette.grey[100],
                color: theme.palette.info.contrastText,
                "-webkit-text-fill-color": theme.palette.info.contrastText,
                opacity: 1,

                "::placeholder": {
                  color: theme.palette.info.contrastText,
                  "-webkit-text-fill-color": theme.palette.info.contrastText,
                },
              },
              fieldset: {
                border: "none",
              },
              ...textFieldProps?.sx,
            }}
          />
        </Tooltip>
      ) : null}
      {counter && limit !== undefined ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" gap="0.3rem">
            {error ? (
              <Fragment>
                <Warning
                  sx={{
                    color: "#DB0D00",
                    height: "1rem",
                    width: "1rem",
                  }}
                />
                <Typography
                  sx={{
                    color: "#DB0D00",
                    fontSize: "0.875rem",
                    fontWeight: 400,
                  }}
                >
                  {t(error)}
                </Typography>
              </Fragment>
            ) : null}
          </Stack>
          <Typography
            sx={{
              textAlign: "right",
              color: error ? "#DB0D00" : theme.palette.info.contrastText,
              fontSize: "0.875rem",
              fontWeight: 400,
            }}
          >
            {/*@ts-ignore*/}
            {(textFieldProps.value && textFieldProps.value?.length) || 0}/
            {limit}
          </Typography>
        </Stack>
      ) : error ? (
        <Stack direction="row" alignItems="center" gap="0.3rem">
          <Warning
            sx={{
              color: "#DB0D00",
              height: "1rem",
              width: "1rem",
            }}
          />
          <Typography
            sx={{
              color: "#DB0D00",
              fontSize: "0.875rem",
              fontWeight: 400,
            }}
          >
            {t(error)}
          </Typography>
        </Stack>
      ) : null}
    </FormGroup>
  )
}

export default Input

/*
(
        <TextField
          {...textFieldProps}
          sx={{
            pointerEvents: disabled ? 'none' : 'auto',
            backgroundColor: disabled ? '#CACDCD' : null,
            '.MuiInputBase-input': {
              padding: '0.5rem',
              borderRadius: '0.25rem',
              transition: 'all 0.2s ease-in-out',
            },
            '.MuiOutlinedInput-input': {
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: error
                ? "#DB0D00"
                : theme.palette.primary.contrastText,

              '&:hover': {
                borderColor: theme.palette.grey[500],
              },

              '&:focus': {
                borderColor: theme.palette.primary.main,
              },
            },
            '.Mui-disabled': {
              backgroundColor: theme.palette.grey[100],
              color: theme.palette.info.contrastText,
              '-webkit-text-fill-color': theme.palette.info.contrastText,
              opacity: 1,

              '::placeholder': {
                color: theme.palette.info.contrastText,
                '-webkit-text-fill-color': theme.palette.info.contrastText,
              },
            },
            fieldset: {
              border: 'none',
            },
            ...textFieldProps?.sx,
          }}
        />
      ) : null}
*/
