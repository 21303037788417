import {useRef, type FC} from "react"
import {Stack, StackProps} from "@mui/material"
import ReactDatePicker, {
  type DatePickerProps,
  registerLocale,
} from "react-datepicker"
import {enGB, enUS} from "date-fns/locale"
import "react-datepicker/dist/react-datepicker.css"
import "../../../../assets/css/custom-date-input.css"
import {CalendarIcon} from "../../../../assets/icons"

const browserLocale = navigator.language || navigator.languages[0] || "en-US"

registerLocale("en-US", enUS)
registerLocale("en-GB", enGB)

type Props = {
  containerProps?: StackProps
} & DatePickerProps

const DateInput: FC<Props> = (props) => {
  const {containerProps, ...rest} = props
  const datePickerRef = useRef<any>(null)

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus()
    }
  }

  return (
    <Stack direction="row" position="relative" {...containerProps}>
      <ReactDatePicker
        ref={datePickerRef}
        className="custom-date-input"
        showMonthDropdown
        showYearDropdown
        locale={browserLocale.toLowerCase().includes("us") ? "en-US" : "en-GB"}
        {...rest}
      />
      <CalendarIcon
        className="custom-date-input__icon"
        height="24"
        width="16"
        onClick={handleIconClick}
      />
    </Stack>
  )
}

DateInput.displayName = "DateInput"

export default DateInput
