import {FC} from "react"
import {create} from "zustand"
import {toast} from "react-toastify"
import {decodeToken} from "react-jwt"
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import {ThemeProvider} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers"
import {Configuration, LogLevel} from "@azure/msal-browser"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import {theme} from "./config"
import {Token, type RootProps} from "./types"
import {ErrorIcon} from "./assets/icons/icons"
import {ConfigProvider} from "./config/ConfigProvider"
import SessionExpired from "./pages/SessionExpired/SessionExpired"
import {Authorization, AuthorizationProvider, Navigation, Toast} from "./lib"
import "./config/i18n"

import "./styles/root.css"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      throwOnError: false, // Set this to false to not throw errors in the UI when a query fails
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      var completedTour = localStorage.getItem("completedTour")
      if (!!completedTour) {
        toast.error(
          "An unexpected error occurred. Please try refresh your page.",
          {
            autoClose: 3000,
            icon: ErrorIcon,
            toastId: "api-error-toast",
          },
        )
      } else {
        var completed = localStorage.getItem("completedTour")
        var interval = setInterval(() => {}, 3000)
        if (!!completed) clearInterval(interval)
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      toast.error(
        "An unexpected error occurred. Please try refresh your page.",
        {
          autoClose: 3000,
          icon: ErrorIcon,
          toastId: "api-error-toast",
        },
      )
    },
  }),
})

const RootComponent: FC<RootProps> = (props) => {
  const {authStore, accountPanelStore, guidedTourStore} = props
  const useAuthStore = create(authStore)
  const useAccountPanelStore = create(accountPanelStore)
  const useGuidedTourStore = create(guidedTourStore)
  const appId = useAuthStore((state) => state.functionAppId)
  const authority: string = useAuthStore((state) => state.functionAuthority)
  const userFlow: string = useAuthStore((state) => state.functionUserFlow)
  const layoutObject = useAuthStore((state) => state.layoutObject)
  const searchParams = new URLSearchParams(window.location.hash.substring(1))
  const error = searchParams.get("error")
  const error_description = searchParams.get("error_description")

  const redirectUri: string = useAuthStore((state) => state.functionRedirectUri)
  const authorityDomain: string = useAuthStore(
    (state) => state.functionAuthorityDomain,
  )
  const token = useAuthStore((state) => state.token)
  const setToken = useAuthStore((state) => state.setToken)

  const showAccountPanel = useAccountPanelStore(
    (state) => state.showAccountPanel,
  )
  const setShowAccountPanel = useAccountPanelStore(
    (state) => state.setShowAccountPanel,
  )
  const setRun = useGuidedTourStore((state) => state.setRun)
  const setCompletedTour = useGuidedTourStore((state) => state.setCompletedTour)

  const msalConfig = {
    auth: {
      clientId: appId,
      authority: `${authority}/${userFlow}`,
      knownAuthorities: [authorityDomain],
      redirectUri: redirectUri, // Remember to all the possible redirect URIs in the Azure Portal
      postLogoutRedirectUri: redirectUri,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      iframeHashTimeout: 10000,
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              return
            default:
              return
          }
        },
      },
    },
  } satisfies Configuration

  const decodedToken = decodeToken<Token>(token)
  const user = decodedToken
    ? {
        userId: decodedToken.sub,
        userName: decodedToken.name,
        email: decodedToken.email,
        firstname: decodedToken.firstname,
        lastname: decodedToken.surname,
      }
    : {
        userId: "",
        userName: "",
        email: "",
        firstname: "",
        lastname: "",
      }

  if (token === "null") {
    return <SessionExpired />
  }

  if (error === "server_error" && error_description?.includes("AADB2C")) {
    return <SessionExpired message="An error has ocurred" />
  }
  return (
    <AuthorizationProvider msalConfig={msalConfig}>
      <ThemeProvider theme={theme}>
        <Authorization appId={appId} token={token} setToken={setToken}>
          <ConfigProvider value={{token, user}}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <QueryClientProvider client={queryClient}>
                <RouterProvider
                  router={createBrowserRouter([
                    {
                      path: "*",
                      element: (
                        <Navigation
                          token={token}
                          showAccountPanel={showAccountPanel}
                          setShowAccountPanel={setShowAccountPanel}
                          setRun={setRun}
                          setCompletedTour={setCompletedTour}
                          paths={layoutObject}
                        />
                      ),
                    },
                  ])}
                />
                <Toast />
              </QueryClientProvider>
            </LocalizationProvider>
          </ConfigProvider>
        </Authorization>
      </ThemeProvider>
    </AuthorizationProvider>
  )
}

export default RootComponent
