import React from "react"
import {StoreApi} from "zustand"
import {AccountPanelStore, AuthStore, GuidedTourStore} from "../types"

export type AppPropsContext = {
  authStore: StoreApi<AuthStore>
  accountPanelStore: StoreApi<AccountPanelStore>
  guidedTourStore: StoreApi<GuidedTourStore>
}
//@ts-expect-error - Initial value is null
const CustomPropsContext = React.createContext<AppPropsContext>(null)

export default CustomPropsContext
