import {
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  useRef,
  forwardRef,
} from "react"
import {Stack, StackProps} from "@mui/material"
import {SearchIcon} from "../../../../assets/icons"
import {useDebouncedCallback} from "use-debounce"

type Props = {
  size?: "small" | "medium"
  state?: "expanded" | "collapsed"
  containerProps?: StackProps
  onChange?: (value: string) => void
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "size" | "state" | "onChange"
>

const defaultProps = {
  size: "medium",
  state: "expanded",
} satisfies Props

const SearchBar = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {size, state, style, containerProps, onChange, ...rest} = {
    ...defaultProps,
    ...props,
  }
  const {sx, ...stackRest} = containerProps || {}
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = useDebouncedCallback((value: string) => {
    onChange?.(value)
  }, 500)

  const handleFocus = () => {
    inputRef.current?.focus()
    if (ref && "current" in ref) {
      ref.current?.focus()
    }
  }

  return (
    <Stack
      component="article"
      role="form"
      direction="row"
      alignItems="center"
      gap="0.75rem"
      onClick={handleFocus}
      sx={{
        boxSizing: "border-box",
        backgroundColor: "#FFFFFF",
        color: "#242D35",
        borderRadius: "0.25rem",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#BDBDBC",
        paddingX: "1.25rem",
        height: size === "small" ? "2rem" : "2.5rem",
        width: state === "collapsed" ? "16.5rem" : "30rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        transition:
          "width: 0.2s ease-in-out, height: 0.2s ease-in-out, color: 0.2s ease-in-out, border-color: 0.2s ease-in-out, border-width: 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out, border 0.2s ease-in-out",
        "&:focus-within": {
          borderBottomWidth: "2px",
          borderBottomColor: "#7A7A79",
        },
        input: {
          "&:placeholder, &:ms-input-placeholder": {
            color: "#7A7A79",
          },
        },
        ...sx,
      }}
      {...stackRest}
    >
      <SearchIcon
        height="1rem"
        width="1rem"
        color="#595958"
        onClick={handleFocus}
      />
      <input
        type="text"
        placeholder="Search"
        style={{
          border: "none",
          outline: "none",
          height: "100%",
          backgroundColor: "transparent",
          color: "inherit",
          flex: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          ...style,
        }}
        onChange={({target: {value}}) => handleChange(value)}
        {...rest}
        ref={ref || inputRef}
      />
    </Stack>
  )
})

SearchBar.displayName = "SearchBar"

export default SearchBar
