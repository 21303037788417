import {
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material"
import {useQuery} from "@tanstack/react-query"
import {createBrowserHistory} from "history"
import {FC, useEffect, useState} from "react"
import {ReactSVG} from "react-svg"
import useStorage, {StorageData} from "../../../../hooks/useStorage"
import {clientsEndpoints} from "../../../../services/api/clients"
import {useClientSessionStore} from "../../../../stores/clientSessionStore"
import {ClientModel, PagedResult} from "../../../../types"
import {TextWithTooltip} from "../../../components/molecules/textWithTooltip"
import Search from "../../molecules/search/search"
import {useTranslation} from "react-i18next"

type Props = {
  showAccountPanel: boolean
  setShowAccountPanel: (showAccountPanel: boolean) => void
  forceNavigation: boolean
}

const SwitchAccount: FC<Props> = (props) => {
  const {showAccountPanel, setShowAccountPanel, forceNavigation} = props
  const [scrollbarWidth, setScrollbarWidth] = useState<number>(4)
  const {getClientsByUserId, getClientsByProjectId} = clientsEndpoints()
  const [filters, setFilters] = useState({searchText: ""})
  const history = createBrowserHistory()
  const {t} = useTranslation()
  const queryParams = new URLSearchParams(history.location.search)
  const [refQuery, setRefQuery] = useState<string | null>(
    queryParams.get("ref"),
  )
  const projectId =
    refQuery === "email" ? location.pathname.split("/")[2] : undefined

  const setClient = useClientSessionStore((state) => state.setClient)
  const storage = useStorage("session", StorageData.crmAccount)

  const clientsQuery = useQuery<PagedResult<ClientModel>>({
    queryKey: ["getClientsByUserId", filters.searchText],
    queryFn: () => {
      const query = `query=${filters.searchText}`
      const limit = 9999
      const queries = `limit=${limit}&${query}`
      return getClientsByUserId(queries)
    },
  })

  const userClientsByProjectIdQuery = useQuery<PagedResult<ClientModel>>({
    queryKey: ["getUserClientsByProjectId", projectId],
    queryFn: () => getClientsByProjectId(projectId || ""),
    enabled: !!projectId,
  })

  useEffect(() => {
    if (
      userClientsByProjectIdQuery.isSuccess &&
      userClientsByProjectIdQuery.data?.rowCount === 1 &&
      clientsQuery.isSuccess &&
      clientsQuery.data?.results.length > 0
    ) {
      const client = clientsQuery.data?.results.find(
        (client) =>
          client.id === userClientsByProjectIdQuery.data?.results[0].id,
      ) as ClientModel

      if (client) {
        handleSelectedClient(client, false)
      }
      //window.history.replaceState(null, "", location.href.split("?")[0]);
      history.replace(location.href.split("?")[0])
      setRefQuery(null)
    }
  }, [
    userClientsByProjectIdQuery.isSuccess,
    userClientsByProjectIdQuery.data?.rowCount,
    clientsQuery.isSuccess,
    clientsQuery.data?.results,
  ])

  const handleSelectedClient = (
    client: ClientModel,
    redirect: boolean = true,
  ) => {
    storage.set(client.crmAccount)
    setClient(client.crmAccount)
    setShowAccountPanel(false)
    setFilters({searchText: ""})
    if (
      forceNavigation ||
      (redirect && !location.pathname.includes("/discover"))
    ) {
      history.push("/")
    }
  }

  return (
    <Drawer
      open={showAccountPanel && refQuery !== "email"}
      onClose={() => (storage.get() ? setShowAccountPanel(false) : null)}
      anchor="right"
      disableEnforceFocus
      sx={{
        zIndex: 1000,
        marginTop: "88px",
        marginBottom: "72px",
        height: "100dvh",
        backdropFilter: "blur(5px)",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(126, 128, 129, 0.3)",
        },
        "& .MuiDrawer-paper": {
          width: "300px",
          backgroundColor: "white",
          backdropFilter: "blur(5px)",
          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Stack overflow={"hidden"}>
        <Stack spacing={2} p={2}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={20} fontWeight={600} color={"#053747"}>
              {t("Select account")}
            </Typography>
            {storage.get() && (
              <IconButton
                sx={{width: 12, height: 12, padding: 2}}
                onClick={() => setShowAccountPanel(false)}
              >
                <ReactSVG src="/icons/close.svg"></ReactSVG>
              </IconButton>
            )}
          </Stack>
          <Divider></Divider>
          <Search
            placeholder={t("Search account")}
            filterState={{filters, setFilters}}
          ></Search>
        </Stack>
        <Stack
          sx={{
            maxHeight: "calc(100dvh - 325px)",
            mr: 0.5,
            overflowY: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: scrollbarWidth,
            },
            "&::-webkit-scrollbar-track": {
              width: scrollbarWidth,
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#7A7A79",
              borderRadius: 5,
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
          onMouseEnter={() => setScrollbarWidth(8)}
          onMouseLeave={() => setScrollbarWidth(4)}
        >
          {!clientsQuery.isLoading ? (
            clientsQuery.data && clientsQuery.data?.results?.length > 0 ? (
              clientsQuery.data?.results.map(
                (client: ClientModel, i: number) =>
                  client.clientName.length > 0 && (
                    <MenuItem
                      key={`${i}_${client.id}`}
                      onClick={() => handleSelectedClient(client)}
                      sx={{
                        padding: 2,
                        borderLeftStyle: "solid",
                        borderLeftWidth: 3,
                        borderLeftColor: "white",
                        color: "#595958",
                        "&:hover": {
                          borderLeftStyle: "solid",
                          borderLeftWidth: 3,
                          borderLeftColor: "#226CA5",
                          color: "#226CA5",
                          backgroundColor: "#F8F8F8",
                        },
                      }}
                    >
                      <Typography fontSize={14} fontWeight={600}>
                        <TextWithTooltip
                          text={client.clientName}
                          limit={27}
                          tooltipProps={{arrow: true, placement: "left"}}
                        />
                      </Typography>
                    </MenuItem>
                  ),
              )
            ) : filters.searchText.length >= 2 ? (
              <Stack justifyContent={"center"} alignItems={"center"}>
                <Typography fontSize={14}>{t("No matches found")}</Typography>
              </Stack>
            ) : null
          ) : (
            <Stack justifyContent={"center"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default SwitchAccount
